<template>
  <v-main>
    <router-view />
    <template v-if="showFooter">
      <dashboard-core-footer />
    </template>
  </v-main>
</template>

<script>
  export default {
    name: 'PrintableDashboardCoreView',
    components: {
      DashboardCoreFooter: () => import('./Footer'),
    },
    props: {
      showFooter: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
